// ScrollableBanner.jsx

import React, { useEffect, useState } from "react";
import "./ScrollableBanner.css";
import { Home_DIV3_SERVICE } from "../../../Constants/Constants";
import { Chip } from "@mui/material";

const ScrollableBanner = () => {
  const colors = ["#fed8cc", "#C5F4F1", "#CDF5BA", "#CCD1FE", "#FECCD5"];
  const [shouldAnimate, setShouldAnimate] = useState(false)

  useEffect(()=>{
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 1000 && scrollY <= 2000) {
        setShouldAnimate(true);
      } else {
        setShouldAnimate(false);
      }

    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },[])

  return (
    <section className="award-carousel">
      <div className={`de-code ${shouldAnimate ? "animate" : ""}`}>
        <h1>Level-Up</h1>
      </div>
      <div className="carousel-container">
        <ul id="banners">
          {Home_DIV3_SERVICE.slice(0, 5).map((item, index) => (
            <li className="banner" id={`card${index + 1}`} key={index}>
              <div
                className="card-body"
                style={{ backgroundColor: `${colors[index]}` }}
              >
                <div>
                  {/* <h2>{item.id}</h2> */}
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  {item.tags.map((tag)=> <Chip
                    label={tag}
                    value="link"
                    variant="outlined"
                    style={{padding:'5px', margin:'5px'}}
                    />
                  )}
                 
              </div>
                <img src={item.imageUrl} alt="" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ScrollableBanner;
