import React, { useEffect, useState } from "react";

import "./PlacementStats.scss"

import * as OurMembersAndTestimonialsApi from "../../../service/OurMembersAndTestimonialsApi";

export function PlacementStats(){

   
    const [placementCompanies, setPlacementCompanies] = useState([]);
    const [stats, setStats] = useState({});
  
    useEffect(() => {
 
      OurMembersAndTestimonialsApi.getAllStats().then(st => setStats(st))
      OurMembersAndTestimonialsApi.getAllPlacementCompanies().then(companies => setPlacementCompanies(companies))
    }, []);

    

    return(
        <div className="PlacementStats">
                  <div className="our-vision">
        <div className="vision-data">
          <h2>Our Vision</h2>
          <p>
          Our vision is to empower businesses by harnessing the transformative power of technology across AI, Big Data, and Software Development. We believe in a future where intelligent insights, data-driven strategies, and innovative software solutions enable organizations to reach new heights. By aligning advanced technology with real-world business needs, we are committed to building scalable, impactful solutions that drive lasting growth and success
          </p>
          <b></b>
          <p>
          For startups, turning a bold vision into a market-ready product requires both innovation and technical expertise. Our team specializes in partnering with emerging companies to bring their ideas to life through cutting-edge AI, Big Data, and Software Development services. Whether it’s building intelligent applications, leveraging data insights, or streamlining development with DevOps, we provide the tools and support needed to accelerate growth. Together, we’ll transform your vision into a game-changing product that stands out in a competitive landscape
          </p>
        </div>
      </div>

      <div className="placement-card">
        {/* <div className="statistics">
          <h2>We Take Pride in Our Numbers</h2>
          <p style={{ width: "90vw", fontSize: "1rem", textAlign: 'center' }}>
            Leveraging the Transformative Potential of Statistics and Data for
            Unmatched Success and Growth
          </p>

          <div className="placement-svg">
            <img style={{ width: '100%' }} src='/images/temp/placementStats.svg' />
          </div>

        </div> */}
        <div className="alumni-works-at">

          <div className="alum-container">
            <h2>Our Alumni Works At</h2>
            <p style={{ textAlign: "center" }}>
              Our Alumni Work at Leading Global Companies: A Testimony to the
              Unwavering Dedication to Excellence and Unparalleled Career
              Advancement Opportunities Offered Here
            </p>
          </div>

          <div className="marquee">
            <ul className="marquee__content">
              {placementCompanies && placementCompanies.length && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
            <ul aria-hidden="true" className="marquee__content">
              {placementCompanies && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
          </div>

          <div className="marquee-reverse">
            <ul className="marquee__content-reverse">
              {placementCompanies && placementCompanies.length && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
            <ul aria-hidden="true" class="marquee__content-reverse">
              {placementCompanies && placementCompanies.length && placementCompanies.map((placementCompany, index) => (
                <li><img key={index} src={placementCompany.logoUrl} alt={`Company ${index}`} /></li>
              ))}
            </ul>
          </div>

        </div>
      </div>

        </div>
    )
}