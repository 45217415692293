import React from "react";
import "./AboutUs.scss"

export function AboutUsDetails() {

    return (
        <div className="AboutUsDetails" >

            <div className="about-container">


                {/* about us details */}

                <div className="about-details">
                    <div className="details-head">
                        <h3 className="custom-underline">The Foundation</h3>
                    </div>
                    <div>
                        <p>
                            Bytecoder, a subsidiary of Oston Technology Pvt Ltd, is driven by
                            a rich history of innovation dating back to 2013. Oston
                            Technology, our parent company, has a remarkable track record with
                            over 15 patents and design registrations, showcasing our
                            commitment to product excellence and innovation. We eagerly seek
                            collaboration opportunities, offering concept ideation, product
                            design, and value engineering services.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">Our Focus</h3>
                    </div>
                    <div>
                        <p>
                        Our focus is on empowering businesses to harness the transformative potential of AI, Big Data, and Software Development. We aim to bridge the gap between ambitious ideas and effective, scalable solutions. By staying at the cutting edge of technology and prioritizing our clients’ goals, we deliver services that help organizations unlock value, drive efficiency, and achieve sustainable growth.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">The Process</h3>
                    </div>
                    <div>
                        <p>
                        Our process is collaborative, transparent, and client-centered. We start by understanding your unique needs and challenges, then tailor our approach to deliver the most impactful solutions. From in-depth discovery and planning to agile development and rigorous testing, we ensure every step is aligned with your vision. Our team remains closely engaged with you throughout, adapting to feedback and optimizing along the way for the best results.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">Solutions</h3>
                    </div>
                    <div>
                        <p>
                        Our solutions are designed to tackle complex business challenges with precision and innovation. Whether it’s implementing advanced AI models, developing robust data analytics systems, or building custom software applications, we provide end-to-end services that are scalable, secure, and tailored to meet your needs. Our goal is to empower you with technology that drives impact and supports long-term success.
                        </p>
                    </div>

                    <div className="details-head">
                        <h3 className="custom-underline">Join Us</h3>
                    </div>
                    <div>
                        <p>
                            Join us on this journey towards a brighter, more inclusive future
                            for aspiring professionals. Together, we'll shape fulfilling
                            careers that make a difference
                        </p>
                    </div>

                    <div className="computer-guy">
                        <img src="/images/computer-guy.svg" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}