import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./CourseProgramDetailsPage.css";
import { JoinButton } from "../../../Atoms/Buttons";
import * as CoursesApi from "../../../service/CoursesApi";


export default function CourseProgramDetailsPage() {
  const params = useParams();
  const [course, setCourse] = useState({ skills: [], learnings: [] });

  const imgaeExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];

  useEffect(() => {
    window.scroll(0,0)

    CoursesApi.getCourseById(params.courseCode).then((cs) => {
      setCourse(cs[0]);
      console.log(cs[0]);
    });
  }, []);

  return (
    <div className="program-container">

      <div className="title-image-card">
        <div className="title-card">
          <img src="../images/temp/certified.svg" alt="byte-coder-logo" />
          <h1>{course.title}</h1>
          <img src="../images/Logo.svg" alt="byte-coder-logo" />

        </div>
        <div className="program-detail-img">
          <img src={course.imageUrl} alt="../images/temp/default.png" />

        </div>

      </div>


      <div className="course-title-and-join">
        <div className="prgrm-title">
          <h4 style={{ margin: 0 }}>{course.title}</h4>
        </div>

        <div className="join-btn">
          <div className="green">
            <Link style={{ cursor: "pointer" }} to={"/login"}>
              <button>Join</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="program-desc">
        <p className="program-desc-para">{course.description}</p>
      </div>
      {/* <div className="logo-and-instructor">
        <div className="logo-inst">
          <img src="/images/Bytecoder - emblem.png" alt="" />
          <p>{"Instuctor  :  " + course.mentor}</p>
        </div>
      </div>
      <div className="program-details">
        <div className="program-duration-fees">
          <div className="duration">
            <p>Duration:</p>
            <p>{course.duration}</p>
          </div>
          <div className="level">
            <p>Level:</p>
            <p>{course.level}</p>
          </div>
          <div className="fees">
            <p>Fees:</p>
            <p> &#x20b9; {course.fee}</p>
          </div>
        </div>
      </div> */}
      <div className="learning-container">
        <div className="learning-box">
          <h4>Keypoints</h4>

          <div className="learning-list">
            <ul className="details-list">
              {course.learnings.map((line) => (
                <li>{line}</li>
              ))}
            </ul>

            <div className="girl-svg">
              <img src="/images/girlSvg.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="skills-gain">
        <h3>Technologies Used</h3>
        <div
          className="skill-shortcuts"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: ".5rem",
            margin: " 1rem 0",
          }}
        >
          {course.skills.map((skill) => (
            <div className="skills-tags">{skill}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
