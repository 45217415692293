import React from "react";
import "../ServicesPage/ServicesPage.css";
import { SERVICE_CARD_DETAILS } from "../../Constants/Constants";
import ServiceCardDetails from "./ServiceCardDetails";

export default function Services() {
  return (
    <div className="services-conatiner">
      <div className="services-details">
        <div className="service-head">
          <h2>Why Bytecoder ?</h2>
          <p style={{ textAlign: "center" }}>
            Elevating Your Journey
          </p>
          <div className="student-img">
            <img src="/images/student.svg" alt="" />
          </div>
        </div>
      </div>

      <div className="services-container">
        <div
          style={{ position: "absolute", top: "200px", left: "30px" }}
          className="globe"
        >
          <img src="/images/globe.svg" alt="" />
        </div>
        <div className="services-columns">
          <div className="services-grid" style={{ marginTop: "2rem" }}>
            {SERVICE_CARD_DETAILS.map((serviceDetail, index) => (
              <div key={index}>
                <ServiceCardDetails serviceDetail={serviceDetail} />
              </div>
            ))}
          </div>
        </div>

        <div
          style={{ position: "absolute", right: 0, bottom: 0 }}
          className="globe1"
        >
          <img src="/images/globe2.svg" alt="" />
        </div>
      </div>
    </div>
  );
}
