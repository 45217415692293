import React from "react";
import "./AboutUsPage.css";
import TeamMembersCard from "./TeamMembers/TeamMembersCard";
import { Banner } from "./Banner/Banner";
import { AboutUsDetails } from "./AboutUs/AboutUsDetails";

export default function AboutUs() {
  return (
    <div>
      <Banner />
      <AboutUsDetails />
      {/* <TeamMembersCard /> */}
    </div>
  );
}
