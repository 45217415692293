
export const COLOR_CODES = { primary: "#0F547E", secondary: "#28B2B6" };

export const Heading_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export const TEXT_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export function getButton_css(tab, selectedIcon) {
  return {
    color: tab.tag === selectedIcon ? "white" : "black",
    backgroundColor: tab.tag === selectedIcon ? "green" : "white",
  };
}

export const HEADERS = [
  { tag: "Home", path: "/home" },
  { tag: "Why Bytecoder", path: "/services" },
  // { tag: "Programs", path: "/programs" },
  // { tag: "Blog", path: "/blogs" },
  // { tag: "Careers", path: "/careers" },
  { tag: "About Us", path: "/about-us" },
  // { tag: "login", path: "/login" }
];

export const FOOTERS = [
  { tag: "Home", path: "/home" },
  { tag: "Why Bytecoder", path: "/services" },
  // { tag: "Services", path: "/services" },
  // { tag: "Programs", path: "/programs" },
  { tag: "About Us", path: "/about-us" },
  // { tag: "Blog", path: "/blogs" },
  // { tag: "Careers", path: "/careers" },
];



// Home page


export const SERVICE_CARD_DETAILS = [
  {
    id: 1,
    title: "Expertise Across Cutting-Edge Technologies",
    number: "01",
    imageUrl: "/images/temp/personalised.jpg",
    description:
      "Our team combines deep expertise in AI, Machine Learning, Big Data, and Software Development to deliver solutions that drive results. With years of experience and specialized skills, we stay at the forefront of technology to ensure you benefit from the latest innovations in the field.",
  },
  {
    id: 2,
    title: "Tailored Solutions for Unique Business Needs",
    number: "02",
    imageUrl: "/images/temp/mentorship.jpeg",

    description:
      "We understand that every business is unique, so we customize our approach to align with your specific goals and challenges. From tailored machine learning models to custom software applications, we develop solutions that are as unique as your vision.",
  },  
  {
    id: 3,
    title: "End-to-End Development and Support",
    number: "03",
    imageUrl: "/images/temp/self-learning.avif",
    description:
      "From initial concept to deployment and beyond, we provide complete end-to-end support. Our services cover every stage of the development lifecycle, ensuring seamless integration, rapid deployment, and continuous optimization to keep your solutions performing at their best.",
  },
  
  {
    id: 4,
    title: "Dedicated to Startups and Growing Businesses",
    number: "04",
    imageUrl: "/images/temp/portal.jpeg",

    description:
      "Startups and scaling companies face unique challenges, and we’re here to help you navigate them. With a keen focus on agility and rapid delivery, we work closely with emerging businesses to turn ambitious ideas into impactful, market-ready products.",
  },
  {
    id: 5,
    title: "Commitment to Innovation and Impact",
    number: "05",
    imageUrl: "/images/temp/softskills.jpeg",

    description:
      "We’re passionate about driving innovation that makes a tangible impact. Whether it's through data-driven insights, intelligent automation, or custom-built applications, our mission is to help you unlock new opportunities and fuel long-term success.",
  },
  {
    id: 6,
    title: "Scalable Solutions for Future Growth",
    number: "06",
    imageUrl: "/images/temp/intership.webp",
    description:
      "Every solution we develop is built to scale with your business. We design our systems and infrastructure to adapt as you grow, ensuring you can expand your capabilities without compromising performance or security.",
  }, 
  {
    id: 7,
    title: "Transparent, Collaborative Process",
    number: "07",
    imageUrl: "/images/temp/interview.jpeg",

    description:
      "We believe that collaboration and transparency are key to successful partnerships. You’ll have full visibility into our process, and we work closely with your team to keep you informed and involved every step of the way.",
  },
  {
    id: 8,
    title: " Proven Track Record of Success",
    number: "08",
    imageUrl: "/images/temp/job.jpeg",
    description:
      "With a portfolio of successful projects across multiple industries, we have a proven record of delivering solutions that work. Our clients have seen real results, from improved efficiency and productivity to significant cost savings and competitive advantages.",
  },
];



const LandingPagetitle = "Get your dream job with bytecoder and AI"

const LandingPageDescription = " \
Bytecoder is an innovative edtech platform designed to empower both freshers and experienced software engineers by providing comprehensive \
support for technical learning , mentoring careerpath, interview preparation, projects and job placements.\
Leveraging the power of artificial intelligence, Bytecoder creates unique career paths, content and mentorship program based on candidates skills and level of experience.\
Bytecoder makes sure that all aspiring professionals have a brighter future in the tech industry.\
"

export const Home_DIV3_SERVICE = [
  {
    id: 1,
    title: "Empowering Your Business with Intelligent AI Solutions",
    number: "01",
    tags: ['AI', 'ML', 'Computer Vision', 'LLM', 'Image Processing'],
    imageUrl: "/images/temp/personalised.jpg",
    description:
      "Unlock new possibilities with our AI, Machine Learning, and Computer Vision solutions. Our team of experts develops custom models tailored to your business needs, transforming data into actionable insights and automating complex processes. From enhancing customer experiences to optimizing operations, our AI-driven solutions are designed to drive measurable results. Ready to see how AI can elevate your business?",
  },
  {
    id: 2,
    title: "Big Data Solutions for Informed Decision-Making",
    number: "02",
    imageUrl: "/images/temp/mentorship.jpeg",
    tags: ['Spark', 'Hadoop', 'AWS', 'Flink', 'Hadoop', 'Datawarehouses', 'Snowflake', 'Airflow'],

    description:
      "Turn your data into a powerful asset with our Big Data Processing and Analysis services. Our team specializes in managing, analyzing, and extracting insights from vast data sets to help you make informed decisions and drive strategic growth. From real-time analytics to predictive modeling, we deliver solutions that empower your business with data-driven intelligence. Ready to unlock the potential of your data?",
  },

  {
    id: 3,
    title: "Build, Deploy, and Scale with Confidence",
    number: "03",
    imageUrl: "/images/temp/portal.jpeg",
    tags: ['AWS', 'Java', 'Python', 'RactJs', 'React Native', 'Devops', 'Jenkins', 'Kafka', 'TDD', 'Agile'],

    description:
      "Accelerate your digital transformation with our comprehensive Software Development and DevOps services. Our team builds scalable, high-performance software solutions tailored to your business needs, while implementing DevOps practices that streamline your development pipeline and boost deployment efficiency. From custom applications to cloud infrastructure, we ensure reliability, security, and seamless integration. Ready to bring your software vision to life?",
  }
];


export const MentorsDetails = [
  {
    id: 0,
    name: "Sayar Choudhary",
    expertise: "Career mentorship",
    education: "IIT-Mumbai, India",
    areaOfInterest: "Team Management, Career Counselling, Enterprenaurship ",
    yearsOfExp: 10,
    image: "/images/CEO.jpg",
  },
  {
    id: 1,
    name: "Devesh Sadarangani",
    expertise: "Machine Learning Expert",
    education: "IIT-Delhi, India",
    areaOfInterest: "Python, Pandas, Machine Learning, Computer vision",
    yearsOfExp: 5,
    image: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/devesh.svg",
  },
  {
    id: 2,
    name: "Karan Sharma",
    expertise: "Devops Expert",
    education: "VIT-Vellore, India",
    areaOfInterest: "Machine Learning, Computer Vision ",
    yearsOfExp: 7,
    image: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/ganesh.svg",
  },
  {
    id: 3,
    name: "Ganesh Choudhary",
    expertise: "Backend Development Expert",
    education: "VIT-Vellore, India",
    areaOfInterest: "Java, Spring, Postgres, System Design, Kafka, Docker,",
    yearsOfExp: 5,
    image: "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/back-end/public/OtherContent/ganesh.svg",
  }
];


